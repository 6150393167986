import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SvgIcon from '@mui/material/SvgIcon';

import BarChartSquare02Icon from 'src/icons/untitled-ui/duocolor/bar-chart-square-02';
import HomeSmileIcon from 'src/icons/untitled-ui/duocolor/home-smile';
import LineChartUp04Icon from 'src/icons/untitled-ui/duocolor/line-chart-up-04';
import { paths } from 'src/paths';
import { useAuth } from 'src/hooks/use-auth';

export const useSections = () => {
  const { t } = useTranslation();
  const auth = useAuth();

  // נוודא שיש מייל תקין, ואז נבדוק אם הוא מסתיים באחת משתי הסיומות
  const userEmail = auth?.user?.email ?? '';
  const isAllowedUser = userEmail.endsWith('hanhala@lemaanimaot.com') || userEmail.endsWith('@pcenter.co.il');

  return useMemo(() => {
    const sections = [
      {
        items: [
          {
            title: 'סקירה כללית',
            path: paths.dashboard.index,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmileIcon />
              </SvgIcon>
            )
          },
          {
            title: 'קווים',
            path: paths.dashboard.lines.index,
            icon: (
              <SvgIcon fontSize="small">
                <BarChartSquare02Icon />
              </SvgIcon>
            )
          },
          {
            title: 'תפריטים קוליים',
            path: paths.dashboard.ivr.index,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmileIcon />
              </SvgIcon>
            )
          },
          {
            title: 'הקלטות',
            path: paths.dashboard.records.index,
            icon: (
              <SvgIcon fontSize="small">
                <BarChartSquare02Icon />
              </SvgIcon>
            )
          },
          {
            title: 'רשימות השמעה',
            path: paths.dashboard.recordslist.index,
            icon: (
              <SvgIcon fontSize="small">
                <LineChartUp04Icon />
              </SvgIcon>
            )
          },
          {
            title: 'העברת שיחות',
            path: paths.dashboard.calltransfer.index,
            icon: (
              <SvgIcon fontSize="small">
                <LineChartUp04Icon />
              </SvgIcon>
            )
          },
          {
            title: 'צינתוקים',
            path: paths.dashboard.tzintuks.index,
            icon: (
              <SvgIcon fontSize="small">
                <LineChartUp04Icon />
              </SvgIcon>
            )
          },
          {
            title: 'חדרי ועידה',
            path: paths.dashboard.conferences.index,
            icon: (
              <SvgIcon fontSize="small">
                <LineChartUp04Icon />
              </SvgIcon>
            )
          },
          {
            title: 'חדרי ועידה מדומה',
            path: paths.dashboard.conferencesmock.index,
            icon: (
              <SvgIcon fontSize="small">
                <LineChartUp04Icon />
              </SvgIcon>
            )
          },
          {
            title: 'סקרים/טריוויה',
            path: paths.dashboard.trivia.index,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmileIcon />
              </SvgIcon>
            )
          },
          {
            title: 'הודעות',
            path: paths.dashboard.messagesnew.index,
            icon: (
              <SvgIcon fontSize="small">
                <LineChartUp04Icon />
              </SvgIcon>
            )
          },
          {
            title: 'קבלת נתונים',
            path: paths.dashboard.getdata.index,
            icon: (
              <SvgIcon fontSize="small">
                <LineChartUp04Icon />
              </SvgIcon>
            )
          }
        ]
      },
      {
        subheader: 'דוחות',
        items: [
          {
            title: 'פרוט שיחות לפי דקות',
            path: paths.dashboard.reportdakot.index,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmileIcon />
              </SvgIcon>
            )
          },
          {
            title: 'פרוט האזנות לפי שם הקלטה',
            path: paths.dashboard.reportrecords.index,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmileIcon />
              </SvgIcon>
            )
          },
          {
            title: 'פרוט מספרים ודקות',
            path: paths.dashboard.reportcallerid.index,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmileIcon />
              </SvgIcon>
            )
          }
        ]
      }
    ];

    // אם המשתמש הוא עם אחת הסיומות המבוקשות - נוסיף את אזור הניהול
    if (isAllowedUser) {
      sections.push({
        subheader: 'ניהול',
        items: [
          {
            title: 'רשימת שרתים',
            path: paths.dashboard.admin,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmileIcon />
              </SvgIcon>
            )
          },
          {
            title: 'ניהול מערכות',
            path: paths.dashboard.systems.index,
            icon: (
              <SvgIcon fontSize="small">
                <BarChartSquare02Icon />
              </SvgIcon>
            )
          }
        ]
      });
    }

    return sections;
  }, [t, isAllowedUser]);
};
