import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Layout as DashboardLayout } from 'src/layouts/dashboard';

const IndexPage = lazy(() => import('src/pages/dashboard/index'));


const AdminPage = lazy(() => import('src/pages/dashboard/admin'));
// Academy
const AcademyDashboardPage = lazy(() => import('src/pages/dashboard/academy/dashboard'));
const AcademyCoursePage = lazy(() => import('src/pages/dashboard/academy/course'));

// Blog
const BlogPostListPage = lazy(() => import('src/pages/dashboard/blog/list'));
const BlogPostDetailPage = lazy(() => import('src/pages/dashboard/blog/detail'));
const BlogPostCreatePage = lazy(() => import('src/pages/dashboard/blog/create'));

// Customer
const CustomerListPage = lazy(() => import('src/pages/dashboard/customers/list'));
const CustomerDetailPage = lazy(() => import('src/pages/dashboard/customers/detail'));
const CustomerEditPage = lazy(() => import('src/pages/dashboard/customers/edit'));

// Invoice
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoices/list'));
const InvoiceDetailPage = lazy(() => import('src/pages/dashboard/invoices/detail'));

// Job
const JobBrowsePage = lazy(() => import('src/pages/dashboard/jobs/browse'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/jobs/create'));
const CompanyDetailPage = lazy(() => import('src/pages/dashboard/jobs/companies/detail'));

// Logistics
const LogisticsDashboardPage = lazy(() => import('src/pages/dashboard/logistics/dashboard'));
const LogisticsFleetPage = lazy(() => import('src/pages/dashboard/logistics/fleet'));

// Order
const OrderListPage = lazy(() => import('src/pages/dashboard/orders/list'));
const OrderDetailPage = lazy(() => import('src/pages/dashboard/orders/detail'));

// Product
const ProductListPage = lazy(() => import('src/pages/dashboard/products/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/products/create'));

// Lines
const LinesListPage = lazy(() => import('src/pages/dashboard/lines/list'));
const LinesCreatePage = lazy(() => import('src/pages/dashboard/lines/create'));

// systems
const SystemsListPage = lazy(() => import('src/pages/dashboard/systems/list'));
const SystemsCreatePage = lazy(() => import('src/pages/dashboard/systems/listrecords'));

// records
const RecordsListPage = lazy(() => import('src/pages/dashboard/records/list'));
const RecordsCreatePage = lazy(() => import('src/pages/dashboard/records/create'));

// recordslist
const RecordslistListPage = lazy(() => import('src/pages/dashboard/recordslist/list'));
const RecordslistCreatePage = lazy(() => import('src/pages/dashboard/recordslist/listrecords'));

//messagesnew
const MessagesnewListPage = lazy(() => import('src/pages/dashboard/messagesnew/list'));
const MessagesnewCreatePage = lazy(() => import('src/pages/dashboard/messagesnew/listrecords'));

//getdata
const GetdataListPage = lazy(() => import('src/pages/dashboard/getdata/list'));
const GetdataCreatePage = lazy(() => import('src/pages/dashboard/getdata/listrecords'));

// calltransfer
const CalltransferListPage = lazy(() => import('src/pages/dashboard/calltransfer/list'));
const CalltransferCreatePage = lazy(() => import('src/pages/dashboard/recordslist/listrecords'));

// tzintuks
const TzintukslistListPage = lazy(() => import('src/pages/dashboard/tzintuks/list'));
const TzintukslistCreatePage = lazy(() => import('src/pages/dashboard/tzintuks/list-records'));

// ivr
const IvrListPage = lazy(() => import('src/pages/dashboard/ivr/list'));
const IvrCreatePage = lazy(() => import('src/pages/dashboard/ivr/listrecords'));

// trivia
const TriviaListPage = lazy(() => import('src/pages/dashboard/trivia/list'));
const TriviaCreatePage = lazy(() => import('src/pages/dashboard/trivia/listrecords'));

// conferences
const ConferencesListPage = lazy(() => import('src/pages/dashboard/conferences/list'));
const ConferenceslistCreatePage = lazy(() => import('src/pages/dashboard/conferences/list-records'));

// conferencesmock
const ConferencesmockListPage = lazy(() => import('src/pages/dashboard/conferencesmock/list'));
const ConferencesmocklistCreatePage = lazy(() => import('src/pages/dashboard/conferencesmock/list-records'));

// reportdakot
const ReportdakotListPage = lazy(() => import('src/pages/dashboard/report-dakot/list'));

// reportrecords
const ReportrecordsListPage = lazy(() => import('src/pages/dashboard/report-records/list'));

// reportrecords
const ReportcalleridListPage = lazy(() => import('src/pages/dashboard/report-callerid/list'));

// events
const EventsListPage = lazy(() => import('src/pages/dashboard/events/list'));
const EventsCreatePage = lazy(() => import('src/pages/dashboard/events/create'))

// seker
const SekerListPage = lazy(() => import('src/pages/dashboard/seker/list'));
const SekerCreatePage = lazy(() => import('src/pages/dashboard/seker/create'));

// Social
const SocialFeedPage = lazy(() => import('src/pages/dashboard/social/feed'));
const SocialProfilePage = lazy(() => import('src/pages/dashboard/social/profile'));

// Other
const AccountPage = lazy(() => import('src/pages/dashboard/account'));
const AnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const CryptoPage = lazy(() => import('src/pages/dashboard/crypto'));
const EcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />
      },
      {
        path: 'academy',
        children: [
          {
            index: true,
            element: <AcademyDashboardPage />
          },
          {
            path: 'courses',
            children: [
              {
                path: ':courseId',
                element: <AcademyCoursePage />
              }
            ]
          }
        ]
      },
      {
        path: 'blog',
        children: [
          {
            index: true,
            element: <BlogPostListPage />
          },
          {
            path: 'create',
            element: <BlogPostCreatePage />
          },
          {
            path: ':postId',
            element: <BlogPostDetailPage />
          }
        ]
      },
      {
        path: 'customers',
        children: [
          {
            index: true,
            element: <CustomerListPage />
          },
          {
            path: ':customerId',
            element: <CustomerDetailPage />
          },
          {
            path: ':customerId/edit',
            element: <CustomerEditPage />
          }
        ]
      },
      {
        path: 'invoices',
        children: [
          {
            index: true,
            element: <InvoiceListPage />
          },
          {
            path: ':invoiceId',
            element: <InvoiceDetailPage />
          }
        ]
      },
      {
        path: 'jobs',
        children: [
          {
            index: true,
            element: <JobBrowsePage />
          },
          {
            path: 'create',
            element: <JobCreatePage />
          },
          {
            path: 'companies',
            children: [
              {
                path: ':companyId',
                element: <CompanyDetailPage />
              }
            ]
          }
        ]
      },
      {
        path: 'logistics',
        children: [
          {
            index: true,
            element: <LogisticsDashboardPage />
          },
          {
            path: 'fleet',
            element: <LogisticsFleetPage />
          }
        ]
      },
      {
        path: 'orders',
        children: [
          {
            index: true,
            element: <OrderListPage />
          },
          {
            path: ':orderId',
            element: <OrderDetailPage />
          }
        ]
      },
      {
        path: 'products',
        children: [
          {
            index: true,
            element: <ProductListPage />
          },
          {
            path: 'create',
            element: <ProductCreatePage />
          }
        ]
      },
      {
        path: 'lines',
        children: [
          {
            index: true,
            element: <LinesListPage />
          },
          {
            path: 'create',
            element: <LinesCreatePage />
          }
        ]
      },
      {
        path: 'systems',
        children: [
          {
            index: true,
            element: <SystemsListPage />
          },
          {
            path: 'create',
            element: <SystemsCreatePage />
          }
        ]
      },
      {
        path: 'records',
        children: [
          {
            index: true,
            element: <RecordsListPage />
          },
          {
            path: 'create',
            element: <RecordsCreatePage />
          }
        ]
      },
      {
        path: 'recordslist',
        children: [
          {
            index: true,
            element: <RecordslistListPage />
          },
          {
            path: ':customerId',
            element: <RecordslistCreatePage />
          },
          {
            path: 'create',
            element: <RecordslistCreatePage />
          }
        ]
      },
      {
        path: 'calltransfer',
        children: [
          {
            index: true,
            element: <CalltransferListPage />
          },
          {
            path: ':customerId',
            element: <RecordslistCreatePage />
          },
          {
            path: 'create',
            element: <RecordslistCreatePage />
          }
        ]
      },
      {
        path: 'tzintuks',
        children: [
          {
            index: true,
            element: <TzintukslistListPage />
          },
          {
            path: ':customerId',
            element: <TzintukslistCreatePage />
          },
          {
            path: 'create',
            element: <TzintukslistCreatePage />
          }
        ]
      },
      {
        path: 'ivr',
        children: [
          {
            index: true,
            element: <IvrListPage />
          },
          {
            path: ':customerId',
            element: <IvrCreatePage />
          },
          {
            path: 'create',
            element: <IvrCreatePage />
          }
        ]
      },
      {
        path: 'trivia',
        children: [
          {
            index: true,
            element: <TriviaListPage />
          },
          {
            path: ':customerId',
            element: <TriviaCreatePage />
          },
          {
            path: 'create',
            element: <TriviaCreatePage />
          }
        ]
      },
      {
        path: 'conferences',
        children: [
          {
            index: true,
            element: <ConferencesListPage />
          },
          {
            path: ':customerId',
            element: <ConferenceslistCreatePage />
          },
        ]
      },
      {
        path: 'conferencesmock',
        children: [
          {
            index: true,
            element: <ConferencesmockListPage />
          },
          {
            path: ':customerId',
            element: <ConferencesmocklistCreatePage />
          },
        ]
      },
      {
        path: 'messagesnew',
        children: [
          {
            index: true,
            element: <MessagesnewListPage />
          },
          {
            path: ':customerId',
            element: <MessagesnewCreatePage />
          },
          {
            path: 'create',
            element: <MessagesnewCreatePage />
          }
        ]
      },
      {
        path: 'getdata',
        children: [
          {
            index: true,
            element: <GetdataListPage />
          },
          {
            path: ':customerId',
            element: <GetdataCreatePage />
          },
          {
            path: 'create',
            element: <GetdataCreatePage />
          }
        ]
      },
      {
        path: 'reportdakot',
        children: [
          {
            index: true,
            element: <ReportdakotListPage />
          }
        ]
      },
      {
        path: 'reportrecords',
        children: [
          {
            index: true,
            element: <ReportrecordsListPage />
          }
        ]
      },
      {
        path: 'reportcallerid',
        children: [
          {
            index: true,
            element: <ReportcalleridListPage />
          }
        ]
      },
      {
        path: 'events',
        children: [
          {
            index: true,
            element: <EventsListPage />
          },
          {
            path: 'create',
            element: <EventsCreatePage />
          }
        ]
      },
      {
        path: 'seker',
        children: [
          {
            index: true,
            element: <SekerListPage />
          },
          {
            path: 'create',
            element: <SekerCreatePage />
          }
        ]
      },
      {
        path: 'admin',
        element: <AdminPage />
      },
      {
        path: 'social',
        children: [
          {
            path: 'feed',
            element: <SocialFeedPage />
          },
          {
            path: 'profile',
            element: <SocialProfilePage />
          }
        ]
      },
      {
        path: 'account',
        element: <AccountPage />
      },
      {
        path: 'analytics',
        element: <AnalyticsPage />
      },
      {
        path: 'blank',
        element: <BlankPage />
      },
      {
        path: 'calendar',
        element: <CalendarPage />
      },
      {
        path: 'chat',
        element: <ChatPage />
      },
      {
        path: 'crypto',
        element: <CryptoPage />
      },
      {
        path: 'ecommerce',
        element: <EcommercePage />
      },
      {
        path: 'file-manager',
        element: <FileManagerPage />
      },
      {
        path: 'kanban',
        element: <KanbanPage />
      },
      {
        path: 'mail',
        element: <MailPage />
      }
    ]
  }
];
